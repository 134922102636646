import { size, colors, typography } from '@everlywell/leaves';
import styled from 'styled-components';

export const Container = styled.div`
  ${typography.bodyTextSmall};
  margin-bottom: ${size.lg}px;

  ul {
    padding-left: 1.1rem;
  }

  a {
    color: ${colors.green4};
    font-weight: ${typography.weight.bold};
    text-decoration: none;

    :hover,
    :active {
      color: ${colors.green3};
    }
  }
`;
