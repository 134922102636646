import {
  size,
  Modal as LeavesModal,
  colors,
  typography,
} from '@everlywell/leaves';
import styled from 'styled-components';

export const Modal = styled(LeavesModal)`
  h3 {
    text-align: center;
    margin-top: ${size.sm}px;
    margin-bottom: ${size.lg}px;
  }
`;

export const Lists = styled.div`
  display: flex;
  gap: ${size.lg}px;
  padding: 0 ${size.sm}px;
  // To push down any content at the bottom of the page
  margin-bottom: ${size.xl6}px;
`;

export const ListContainer = styled.div`
  width: 50%;

  p {
    ${typography.bodyText}
    font-weight: ${typography.weight.bold};
    color: ${colors.green5};
    margin-bottom: ${size.xs1}px;
  }

  ul {
    list-style-position: outside;
  }

  li {
    ${typography.bodyTextSmall}
  }

  .image-container {
    display: flex;
    justify-content: center;
    margin-bottom: ${size.lg}px;
  }
`;
