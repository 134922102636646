import { H3 } from '@everlywell/leaves';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { Dispatch, SetStateAction } from 'react';
import { ImageType } from 'utils/types';

import * as S from './ComparisonListModal.styles';

export type ComparisonListSide = {
  title: string;
  items: Array<{ content: string }>;
  image: ImageType;
};

export type ComparisonList = {
  title: string;
  leftSide: ComparisonListSide;
  rightSide: ComparisonListSide;
};

export type Props = {
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  comparisonList: ComparisonList;
};

const ComparisonListModal = ({
  isModalOpen,
  setIsModalOpen,
  comparisonList: { title, leftSide, rightSide },
}: Props): JSX.Element => (
  <S.Modal open={isModalOpen} openHandler={setIsModalOpen} persist>
    <H3>{title}</H3>

    <S.Lists>
      <S.ListContainer>
        <div className="image-container">
          <GatsbyImage
            image={leftSide.image.gatsbyImageData}
            alt={leftSide.title}
          ></GatsbyImage>
        </div>
        <p>{leftSide.title}</p>
        <ul>
          {leftSide.items.map(({ content }) => (
            <li key={content}>{content}</li>
          ))}
        </ul>
      </S.ListContainer>

      <S.ListContainer>
        <div className="image-container">
          <GatsbyImage
            image={rightSide.image.gatsbyImageData}
            alt={rightSide.title}
          ></GatsbyImage>
        </div>
        <p>{rightSide.title}</p>
        <ul>
          {rightSide.items.map(({ content }) => (
            <li key={content}>{content}</li>
          ))}
        </ul>
      </S.ListContainer>
    </S.Lists>
  </S.Modal>
);

export default ComparisonListModal;
