import { useHooks } from '@everlywell/leaves';
import AnchorLink from 'components/AnchorLink';
import parse from 'html-react-parser';
import React from 'react';
import { sanitize } from 'utils/domHelpers';
import { CTA } from 'utils/types';

import * as S from './SubHeadline.styles';

export type SubHeadlineProps = {
  /** Sanitized HTML text */
  text?: string;
  /** Section anchor link call to action */
  cta?: CTA;
  /** Function called on cta click */
  onCtaClick?: (linkId: string) => void;
};

/**
 * [Add the description for what this component does here].
 */
function SubHeadline({ text, cta, onCtaClick }: SubHeadlineProps) {
  const { isMobile } = useHooks.useWindowSize();

  if (!text && !cta) {
    return null;
  }

  return isMobile ? (
    <S.Container className="subheadline">
      {text ? <>{parse(sanitize(text))} &nbsp;</> : null}

      {cta ? (
        <AnchorLink
          href={`#${cta.url.toLowerCase()}`}
          onClick={() => onCtaClick && onCtaClick(`${cta.url.toLowerCase()}`)}
        >
          {cta.text}
        </AnchorLink>
      ) : null}
    </S.Container>
  ) : (
    <S.Container className="subheadline">
      {text ? (
        <>
          {parse(sanitize(text))}
          <br />
        </>
      ) : null}

      {cta ? (
        <AnchorLink
          href={`#${cta.url.toLowerCase()}`}
          offset="50"
          onClick={() => onCtaClick && onCtaClick(`${cta.url.toLowerCase()}`)}
        >
          {cta.text}
        </AnchorLink>
      ) : null}
    </S.Container>
  );
}

export default SubHeadline;
