import { Row, Col } from '@everlywell/leaves';
import React, { useState, useEffect, useContext } from 'react';
import { useSpring, useTransition } from 'react-spring';
import ewAnalytics from 'utils/analytics';
import { ANALYTICS } from 'utils/constants/analytics';
import { ProductInfoContext } from 'utils/helpers/pdpContexts';

import { menuItem } from '../../DetailsSection';
import * as S from './styles';

export interface DesktopSectionProps {
  menuArray: menuItem[];
  contentArray: any[];
  activeIndex: number;
  setActiveIndex: (index: number) => void;
}

export const DesktopMenu: React.FC<DesktopSectionProps> = ({
  menuArray,
  contentArray,
  activeIndex,
  setActiveIndex,
}) => {
  const [offsetTop, setOffsetTop] = useState<number>(0);
  const { name, sku } = useContext(ProductInfoContext);
  const [arrowTransition] = useSpring(
    () => ({
      transform: `translateY(${offsetTop}px)`,
    }),
    [offsetTop],
  );

  // Makes the whole componenet reactive based on the activeIndex
  // So setting it from props will work 'prop'erly.
  useEffect(() => {
    const setOffsetFromIndex = (tabIndex: number) => {
      const currentTab = getDetailsTabFromIndex(tabIndex);
      setOffsetTop(currentTab.offsetTop);
    };

    setOffsetFromIndex(activeIndex);
  }, [activeIndex]);

  const getDetailsTabFromIndex = (index: number) =>
    document.querySelector(`[id$=details-${index}]`) as HTMLElement;

  const handleClick = (
    categoryIndex: number,
    e: React.SyntheticEvent<HTMLButtonElement>,
  ) => {
    const element = e.target as HTMLElement;
    if (element) {
      setActiveIndex(categoryIndex);
      ewAnalytics.track({
        event: ANALYTICS.EVENTS.CLICKED_BUTTON,
        data: {
          label: `PDP Details Section - ${menuArray[categoryIndex].menuTitle}`,
          product: name,
          sku: sku,
        },
      });
    }
  };

  const contentTransition = useTransition(activeIndex, {
    from: {
      opacity: -5,
    },
    enter: {
      position: 'static',
      opacity: 1,
    },
    leave: {
      position: 'absolute',
      opacity: 0,
      immediate: true,
    },
  });

  return (
    <Row>
      <Col xs={12} md={4}>
        <S.SectionTitle>Details</S.SectionTitle>
        <S.Menu id="details-desktop">
          <S.AnimatedArrow style={arrowTransition}>
            <S.Arrow />
          </S.AnimatedArrow>
          {menuArray.map(({ menuTitle, slug }, i: number) => (
            <S.MenuItem
              id={`details-${i}`}
              data-slug={`details-${slug}`}
              data-index={i}
              key={i}
              // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
              onClick={(e: React.SyntheticEvent<HTMLButtonElement, Event>) =>
                handleClick(i, e)
              }
            >
              {menuTitle}
            </S.MenuItem>
          ))}
        </S.Menu>
      </Col>
      <Col xs={12} md={8}>
        {contentTransition((values, item) => (
          <S.ContentContainer style={values}>
            {contentArray[item]}
          </S.ContentContainer>
        ))}
      </Col>
    </Row>
  );
};

export default DesktopMenu;
