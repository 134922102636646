import { typography, size, mediaQueries, colors } from '@everlywell/leaves';
import styled, { css } from 'styled-components';

export const LargerCentralizedHeadline = styled.h2`
  ${typography.h3Text};
  ${mediaQueries.forTabletHorizontalUp} {
    text-align: center;
    margin-bottom: ${size.xl2}px;
  }

  ${mediaQueries.forTabletVerticalDown} {
    font-size: 2.566rem;
    line-height: 3.5rem;
    letter-spacing: 0.51px;
    text-align: left;
    margin-bottom: ${size.lg}px;
  }
`;

export const LargerNotCentralizedHeadline = styled.h2`
  ${typography.h3Text};
  margin-top: ${size.xs1}px;
  margin-bottom: ${size.xs1}px;
`;

export const SubHeadline = styled.div`
  ${typography.bodyTextSmall};
  margin-bottom: ${size.lg}px;

  ul {
    padding-left: 1.1rem;
  }

  a {
    color: ${colors.green4};
    font-weight: ${typography.weight.bold};
    text-decoration: none;

    :hover,
    :active {
      color: ${colors.green3};
    }
  }
`;

export const ListContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${size.xl2}px;
  text-align: left;

  ${mediaQueries.forTabletVerticalUp} {
    grid-template-columns: repeat(2, auto);
  }

  ${mediaQueries.forDesktopUp} {
    grid-template-columns: repeat(3, auto);
  }
`;

export const ListItemWrapper = styled.div<{ constrainWidth: boolean }>`
  display: flex;
  ${({ constrainWidth }) =>
    constrainWidth &&
    css`
      ${mediaQueries.forTabletHorizontalUp} {
        max-width: 392px;
      }
    `};
`;

export const ListItemIcon = styled.img`
  width: ${size.xl1}px;
  height: ${size.xl1}px;
  margin-right: ${size.lg}px;
`;

export const ListItemTitle = styled.h3`
  ${typography.subtitle}
  margin-bottom: ${size.xs3}px;
`;

export const UnorderedList = styled.ul`
  list-style: disc outside;
  margin-left: ${size.md}px;
`;

export const ListSubItem = styled.li`
  ${typography.bodyTextSmall};
`;
