import React from 'react';
import { CTA, ImageType } from 'utils/types';

import { DetailsSection } from '../../../components/PDP/DetailsSection';
import { ComparisonList } from '../ComparisonListModal';

export interface DetailsContainerProps {
  content: {
    listOfContentSections: {
      title: string;
      headline: string;
      subHeadline?: string;
      slug: string;
      description?: {
        description: string;
      };
      cta?: CTA;
      desktopImages?: Array<ImageType>;
      mobileImages?: Array<ImageType>;
      modalButtonText?: string;
      modalTitle?: string;
      embeddedVideoLink?: string;
      pdfFile?: {
        title: string;
        file: {
          url: string;
        };
      };
      listOfContentSections?: Array<{
        title: string;
        description: {
          description: string;
        };
        image: {
          file: {
            url: string;
          };
        };
        desktopImages?: Array<ImageType>;
        mobileImages?: Array<ImageType>;
        cta?: CTA;
        listOfContentSections?: Array<{
          title: string;
        }>;
      }>;
    }[];
    comparisonList?: ComparisonList;
    modalButtonText: string;
  };
}

export const DetailsContainer: React.FC<DetailsContainerProps> = ({
  content,
}) => {
  if (!content) return null;
  const { listOfContentSections, comparisonList, modalButtonText } = content;
  const props = listOfContentSections.map((section) => {
    let sectionTitle = section.title;
    let innerListOfContentSections = section.listOfContentSections;

    return {
      title: sectionTitle,
      slug: section.slug,
      headline: section.headline,
      subheadline: section.subHeadline,
      copy: section.description?.description,
      cta: section.cta,
      desktopImages: section.desktopImages,
      mobileImages: section.mobileImages,
      modalButtonText: section.modalButtonText,
      modalTitle: section.modalTitle,
      link: section.embeddedVideoLink,
      pdfFileUrl: section.pdfFile?.file.url,
      pdfFileLabel: section.pdfFile?.title,
      listOfContent: innerListOfContentSections?.map((section) => ({
        title: section.title,
        description: section.description?.description,
        image: section.image?.file?.url,
        desktopImages: section.desktopImages,
        mobileImages: section.mobileImages,
        cta: section.cta,
        listOfContentSections: section.listOfContentSections,
      })),
    };
  });

  return (
    <DetailsSection
      content={props}
      comparisonList={comparisonList}
      modalButtonText={modalButtonText}
    />
  );
};

export default DetailsContainer;
