import { size, typography, colors, Icons } from '@everlywell/leaves';
import { animated as A } from 'react-spring';
import styled from 'styled-components';

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const SectionTitle = styled.h2`
  ${typography.overlineText}
  margin-bottom: ${size.lg}px;
  margin-left: ${size.lg}px;
  width: 100%;
`;

const MenuItem = styled.button`
  ${typography.buttonText};
  color: ${colors.green5};
  background: none;
  border: none;
  cursor: pointer;
  text-align: left;
  padding: 0;
  margin-bottom: ${size.xl1}px;
  margin-left: ${size.lg}px;
`;

const AnimatedArrow = styled(A.div)`
  position: absolute;
  left: 0;
  top: -2px;
`;

const Arrow = styled(Icons.RightArrow)`
  width: 7px;
  height: 12px;
  color: ${colors.red2};
`;

const ContentContainer = styled(A.div)`
  position: relative;
  top: 0;
`;

export { Menu, SectionTitle, MenuItem, AnimatedArrow, Arrow, ContentContainer };
