import {
  mediaQueries,
  size,
  typography,
  colors,
  Icons,
  Accordion as AccordionBase,
  TertiaryLink as TertiaryLinkBase,
} from '@everlywell/leaves';
import { animated as A } from 'react-spring';
import styled from 'styled-components';

import checkMarkBullet from './images/check.svg';

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const SectionTitle = styled.h2`
  ${typography.overlineText}
  margin-bottom: ${size.lg}px;
  margin-left: ${size.lg}px;
  width: 100%;
`;

const MenuItem = styled.button`
  ${typography.buttonText};
  color: ${colors.green5};
  background: none;
  border: none;
  cursor: pointer;
  text-align: left;
  padding: 0;
  margin-bottom: ${size.xl1}px;
  margin-left: ${size.lg}px;
`;

const AnimatedArrow = styled(A.div)`
  position: absolute;
  left: 0;
  top: -2px;
`;

const Arrow = styled(Icons.RightArrow)`
  width: 7px;
  height: 12px;
  color: ${colors.red2};
`;

const ContentContainer = styled(A.div)`
  position: relative;
  top: 0;
`;

const SectionHeadline = styled.h2`
  ${typography.h3Text};
  margin-top: ${size.xs1}px;
  margin-bottom: ${size.xs1}px;
`;

const SectionCopy = styled.div`
  ${typography.bodyTextSmall};
  margin-bottom: ${size.lg}px;

  ul {
    padding-left: 1.1rem;
  }
`;

const AccordionHeading = styled(AccordionBase.Heading)`
  color: ${colors.green5};
  text-align: left;
`;

const Divider = styled.div`
  border-bottom: 1px solid ${colors.gray2};
  margin: 0 -${size.lg}px;
`;

const SectionContentContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: ${size.xl1}px;
`;

//Symptom Specific Components
const SymptomContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${size.xl1}px;

  ${mediaQueries.forTabletHorizontalUp} {
    grid-template-columns: repeat(2, auto);
  }

  ${mediaQueries.forDesktopUp} {
    grid-template-columns: repeat(3, auto);
  }
`;

const SymptomWrapper = styled.div`
  display: flex;
`;

const SymptomIcon = styled.img`
  width: ${size.xl1}px;
  height: ${size.xl1}px;
  margin-right: ${size.lg}px;
`;

const SymptomTitle = styled.h3`
  ${typography.subtitle}
  margin-bottom: ${size.xs3}px;
`;

const SymptomList = styled.ul`
  list-style: none;
`;

const Symptom = styled.li`
  ${typography.bodyTextSmall};
  list-style: disc inside;
`;

const VideoContainer = styled.div`
  overflow: hidden;
  padding-top: 56.25%; /* 16:9 aspect ratio */
  position: relative;
`;

const Video = styled.iframe`
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

// What's Measured Specific Components
const SectionSubheadline = styled.h3`
  ${typography.subtitle}
  margin-top: ${size.lg}px;
`;

// TODO: the tertiary link needs update in DLS to have Button props
const TertiaryLink = styled(TertiaryLinkBase).attrs((props) => ({
  role: props.role,
  onClick: props.onClick,
  target: props.target,
}))`
  color: ${colors.green5};
`;

const MarkerModalTitle = styled(SectionHeadline)`
  text-align: center;
  margin-bottom: ${size.xl2}px;
  ${mediaQueries.forTabletHorizontalUp} {
    margin-bottom: ${size.xl1}px;
  }
`;

const MarkerContainer = styled.div`
  margin-left: 14px;
  margin-bottom: ${size.xl2}px;

  ${mediaQueries.forTabletVerticalUp} {
    margin-left: 88px;
  }

  ${mediaQueries.forTabletHorizontalUp} {
    margin-left: 24px;
  }
`;

const MarkerTitleContainer = styled.div`
  display: flex;
  margin-bottom: ${size.sm}px;
`;

const MarkerIcon = styled.img`
  height: ${size.lg}px;
  width: ${size.lg}px;
  margin-right: ${size.sm}px;
`;

const MarkerTitle = styled.h3`
  font-size: 18px;
  line-height: ${size.lg}px;
  letter-spacing: 0.45px;
`;

const MarkerList = styled.ul`
  display: flex;
  flex-wrap: wrap;
`;

const Marker = styled.li`
  margin-bottom: ${size.md}px;
  color: ${colors.gray4};
  margin-left: 18px;
  font-weight: ${typography.weight.book};
  flex: 0 1 calc(50% - 18px);
  ${mediaQueries.forTabletHorizontalUp} {
    flex: 0 1 calc(33% - 18px);
  }
`;

export const MarkerListContainer = styled.div`
  display: grid;
  grid-gap: ${size.xl1}px;
  grid-template-columns: 1fr;

  ${mediaQueries.forTabletHorizontalUp} {
    grid-template-columns: 1fr 1fr;
    padding-bottom: ${size.lg}px;
  }
`;

export const MarkerName = styled.h3`
  ${typography.subtitle}
`;

export const MarkerDescription = styled.p`
  ${typography.bodyTextSmall}
`;

export const MarkerWrapper = styled.div``;

export const SectionList = styled.ul`
  padding-top: ${size.lg}px;
  list-style: none;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${size.md}px;

  ${mediaQueries.forTabletHorizontalUp} {
    grid-template-columns: 1fr 1fr;
  }
`;

export const SectionListItem = styled.li`
  ${typography.bodyTextSmall}
  display: flex;
  align-items: baseline;
  position: relative;
  padding-left: ${size.md}px;

  &::before {
    content: '';
    display: block;
    width: 9px;
    height: 8px;
    background-image: url(${checkMarkBullet});
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: ${size.xs1}px;
    position: absolute;
    left: 0;
    top: ${size.sm}px;
  }

  ${mediaQueries.forTabletHorizontalUp} {
    ${typography.bodyText}
  }
`;

export const SectionLink = styled.a`
  ${typography.buttonText};
  color: ${colors.green5};
  display: flex;
  align-items: center;
  text-decoration: none;
`;

export const CTA = styled(SectionLink)`
  padding: ${size.sm}px 0;
`;

export const NewWindowIcon = styled(Icons.NewWindow)`
  width: 17px;
  height: 17px;
  margin-left: ${size.xs1}px;
`;

const Centered = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ExtraInstructions = styled.div`
  display: flex;
  flex-direction: column;
  ${typography.subtitle}
  color: ${colors.green5};
  gap: ${size.md}px;
  margin-bottom: ${size.lg}px;
  margin-left: ${size.md}px;

  a {
    color: ${colors.green5};
    text-decoration: none;

    :hover,
    :active {
      text-decoration: underline;
    }
  }
`;

export const SymptomsListModalWrapper = styled.div`
  margin-top: ${size.lg}px;
`;

export {
  Menu,
  SectionTitle,
  MenuItem,
  AnimatedArrow,
  Arrow,
  ContentContainer,
  SectionHeadline,
  SectionCopy,
  AccordionHeading,
  Divider,
  Centered,
  SectionContentContainer,
  SymptomContainer,
  SymptomWrapper,
  SymptomIcon,
  SymptomTitle,
  SymptomList,
  Symptom,
  VideoContainer,
  Video,
  SectionSubheadline,
  TertiaryLink,
  MarkerContainer,
  MarkerModalTitle,
  MarkerTitleContainer,
  MarkerIcon,
  MarkerTitle,
  MarkerList,
  Marker,
};
