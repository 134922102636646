import {
  Container,
  useHooks,
  Accordion,
  TertiaryLink,
} from '@everlywell/leaves';
import DescriptiveList from 'components/DescriptiveList';
import MeasuredItemsModal from 'components/MeasuredItemsModal';
import SectionWrapper from 'components/SectionWrapper';
import ComparisonListModal, {
  ComparisonList,
} from 'containers/PDP/ComparisonListModal';
import { GatsbyImage as Img } from 'gatsby-plugin-image';
import React, { useState, useContext, useEffect, useLayoutEffect } from 'react';
import ewAnalytics from 'utils/analytics';
import { ANALYTICS } from 'utils/constants/analytics';
import { sanitize } from 'utils/domHelpers';
import { ProductInfoContext } from 'utils/helpers/pdpContexts';
import { CTA, ImageType } from 'utils/types';

import DesktopMenu from './DesktopMenu';
import * as S from './styles';

type SectionType = {
  title: string;
  slug: string;
  headline: string;
  subheadline?: string;
  copy?: string;
  desktopImages?: Array<ImageType>;
  mobileImages?: Array<ImageType>;
  modalButtonText?: string;
  modalTitle?: string;
  link?: string;
  cta?: CTA;
  pdfFileUrl?: string;
  pdfFileLabel?: string;
  listOfContent?: Array<{
    title: string;
    description: string;
    image: string;
    cta?: CTA;
  }>;
};
export type menuItem = {
  menuTitle: string;
  slug: string;
};
export interface DetailsSectionProps {
  content: SectionType[];
  comparisonList?: ComparisonList;
  modalButtonText?: string;
}

export const DetailsSection: React.FC<DetailsSectionProps> = ({
  content,
  comparisonList,
  modalButtonText,
}) => {
  const { isMobile } = useHooks.useWindowSize();
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const { markerList, name, sku } = useContext(ProductInfoContext);
  const [isComparisonModalOpen, setIsComparisonModalOpen] =
    useState<boolean>(false);

  const onComparisonModalOpen = () => {
    setIsComparisonModalOpen(true);
    ewAnalytics.track({
      event: ANALYTICS.EVENTS.CLICKED_MODAL,
      data: {
        label: `${modalButtonText} - Related Symptoms`,
      },
    });
  };

  const RelatedSymptomsContent: React.FC<SectionType> = (section) => (
    <div>
      <DescriptiveList
        headline={section.headline}
        subheadline={section.copy}
        listItems={section.listOfContent || []}
        hasLargerCentralizedHeadline={false}
      />

      {comparisonList && modalButtonText ? (
        <S.SymptomsListModalWrapper>
          <TertiaryLink onClick={() => onComparisonModalOpen()}>
            {modalButtonText}
          </TertiaryLink>

          <ComparisonListModal
            isModalOpen={isComparisonModalOpen}
            setIsModalOpen={setIsComparisonModalOpen}
            comparisonList={comparisonList}
          />
        </S.SymptomsListModalWrapper>
      ) : null}
    </div>
  );

  const WhatsMeasuredContent: React.FC<SectionType> = (section) => {
    const [open, setOpen] = useState(false);

    const handleModalClick = (title: string) => {
      setOpen(true);
      ewAnalytics.track({
        event: ANALYTICS.EVENTS.CLICKED_BUTTON,
        data: {
          label: `Open Modal - ${title}`,
        },
      });
    };

    const formattedListOfItems = section.listOfContent?.map((item) => {
      const formattedItemNames = item.description?.split('\n');
      return {
        image: item.image || '',
        title: item.title,
        items: formattedItemNames,
      };
    });
    return (
      <div>
        <S.SectionHeadline>{section.headline}</S.SectionHeadline>
        {section.subheadline ? (
          <S.SectionSubheadline>{section.subheadline}</S.SectionSubheadline>
        ) : (
          <div style={{ marginTop: '24px' }} />
        )}
        {section.copy ? (
          <S.SectionCopy
            dangerouslySetInnerHTML={{
              __html: sanitize(section.copy),
            }}
          />
        ) : null}
        {section.modalButtonText ? (
          <>
            <S.TertiaryLink
              role="button"
              onClick={() => {
                handleModalClick(section.modalButtonText || '');
              }}
              data-testid="whatsMeasuredModalTrigger"
            >
              {section.modalButtonText}
            </S.TertiaryLink>
            <MeasuredItemsModal
              open={open}
              openHandler={setOpen}
              listOfItems={formattedListOfItems || []}
              modalTitle={section.modalTitle || ''}
            />
          </>
        ) : (
          <S.MarkerListContainer data-testid="whatsMeasuredMarkers">
            {markerList.markers?.map((marker) => (
              <S.MarkerWrapper key={marker.title}>
                <S.MarkerName>{marker.title}</S.MarkerName>
                <S.MarkerDescription>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: sanitize(marker.description?.description),
                    }}
                  />
                </S.MarkerDescription>
              </S.MarkerWrapper>
            ))}
          </S.MarkerListContainer>
        )}
      </div>
    );
  };

  const CollectionMethodsContent: React.FC<SectionType> = (section) => {
    const images = isMobile ? section.mobileImages : section.desktopImages;
    return (
      <div>
        <S.SectionHeadline>{section.headline}</S.SectionHeadline>
        <div>
          {images
            ? images?.map((imageData, index) => (
                <S.SectionContentContainer key={index}>
                  <Img
                    image={imageData.gatsbyImageData}
                    style={{ width: '75%' }}
                    alt={imageData.description || ''}
                  />
                </S.SectionContentContainer>
              ))
            : null}
          {section.copy && section.link ? (
            <S.SectionLink
              target={isMobile ? '_self' : '_blank'}
              href={section.link}
            >
              {section.copy}
              <S.NewWindowIcon />
            </S.SectionLink>
          ) : null}
          {section.listOfContent
            ? section.listOfContent.map(({ cta }) =>
                cta ? (
                  <S.Centered>
                    <S.CTA
                      target={isMobile ? '_self' : '_blank'}
                      href={cta.url}
                    >
                      {cta.text}
                      <S.NewWindowIcon />
                    </S.CTA>
                  </S.Centered>
                ) : null,
              )
            : null}
        </div>
      </div>
    );
  };

  const WhatsInTheBoxContent: React.FC<SectionType> = (section) => {
    const images = isMobile ? section.mobileImages : section.desktopImages;
    const whatsIncludedItems = section.listOfContent
      ? section.listOfContent
      : [];

    const trackLinkClick = (source: string) => {
      ewAnalytics.track({
        event: ANALYTICS.EVENTS.CLICKED_LINK,
        data: {
          label: `${section.title} - ${source}`,
        },
      });
    };

    return (
      <div>
        <S.SectionHeadline>{section.headline}</S.SectionHeadline>
        <div>
          <S.ExtraInstructions>
            {section.pdfFileUrl ? (
              <a
                href={section.pdfFileUrl}
                target="_blank"
                onClick={() => trackLinkClick('PDF')}
                rel="noreferrer noopener"
              >
                {section.pdfFileLabel} <S.NewWindowIcon />
              </a>
            ) : null}
            {section.copy && section.link ? (
              <a target="_blank" href={section.link} rel="noreferrer noopener">
                {section.copy}
                <S.NewWindowIcon />
              </a>
            ) : null}
          </S.ExtraInstructions>

          {images
            ? images?.map((imageData, index) => (
                <S.SectionContentContainer key={index}>
                  <Img
                    image={imageData.gatsbyImageData}
                    style={{ width: '100%' }}
                    alt={imageData.description}
                  />
                </S.SectionContentContainer>
              ))
            : null}
          {whatsIncludedItems.length ? (
            <S.SectionList>
              {whatsIncludedItems?.map((item, index) => (
                <S.SectionListItem key={index}>{item.title}</S.SectionListItem>
              ))}
            </S.SectionList>
          ) : null}
        </div>
      </div>
    );
  };
  const DefaultContent: React.FC<SectionType> = ({
    headline,
    subheadline,
    copy,
    listOfContent,
    cta,
  }) => (
    <div>
      <S.SectionHeadline>{headline}</S.SectionHeadline>
      <S.SectionSubheadline>{subheadline}</S.SectionSubheadline>
      {copy ? (
        <S.SectionCopy
          dangerouslySetInnerHTML={{
            __html: sanitize(copy),
          }}
        />
      ) : null}
      {cta ? (
        <S.TertiaryLink target="_blank" href={cta.url}>
          {cta.text}
        </S.TertiaryLink>
      ) : null}
      {listOfContent
        ? listOfContent.map(({ cta }) =>
            cta ? (
              <S.TertiaryLink href={cta.url}>{cta.text}</S.TertiaryLink>
            ) : null,
          )
        : null}
    </div>
  );
  //create menu and content component map
  const menuArray: menuItem[] = [];
  const contentArray = content.map((section) => {
    menuArray.push({ menuTitle: section.title, slug: section.slug });
    switch (section.slug) {
      case 'relatedSymptoms':
        return <RelatedSymptomsContent {...section} />;
      case 'whatsMeasured':
        return <WhatsMeasuredContent {...section} />;
      case 'collectionMethods': // TODO: update this in a separate MR to go along with the slug update in contentful
        return <CollectionMethodsContent {...section} />;
      case 'whatsInTheBox': // TODO: update this in a separate MR to go along with the slug update in contentful
        return <WhatsInTheBoxContent {...section} />;
      default:
        return <DefaultContent {...section} />;
    }
  });

  const handleClick = (sectionTitle: string) => {
    ewAnalytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: `PDP Details Section - ${sectionTitle}`,
        product: name,
        sku: sku,
      },
    });
  };

  const [openChild, setOpenChild] = useState(``);

  useEffect(() => {
    const handleFragment = () => {
      const hash = window.location.hash.substring(1);
      if (hash) {
        setOpenChild(hash);
        // Resetting the location hash immediately was not allowing the UI to scroll to the correct location.
        setTimeout(() => {
          resetLocationHash();
        }, 1000);
      }
    };

    window.addEventListener('hashchange', handleFragment, false);
    return () => {
      window.removeEventListener('hashchange', handleFragment, false);
    };
  }, []);

  // runs only one time on initial mount - set initial openChild if there is one
  useEffect(() => {
    const hash = window.location.hash.substring(1);
    if (hash) {
      setOpenChild(hash);
    }
  }, []);

  useLayoutEffect(() => {
    // isMobile variable doesn't seem to react to changes in window after load?
    // TODO: This isn't great, but it works for now. There should be a better way to query for `isMobile`
    const isDesktop = document.getElementById('details-desktop');

    const scrollToDetailsSection = () => {
      const detailsSection = document.getElementById('details-desktop');
      if (detailsSection) scrollToElement(detailsSection);
    };

    const scrollToAccordionHash = (hash: string) => {
      const activeAccordion = document.querySelector(
        `[id$="${hash}" ]`,
      ) as HTMLElement;

      if (activeAccordion) scrollToElement(activeAccordion, { offset: 125 });
    };

    if (openChild) {
      if (isDesktop) {
        scrollToDetailsSection();
        setActiveDetailsDesktop(openChild);
      } else {
        scrollToAccordionHash(openChild);
      }
    }
  }, [openChild]);

  const setActiveDetailsDesktop = (hash: string) => {
    const detailsTab = document.querySelector(
      `[data-slug="${hash}" ]`,
    ) as HTMLElement;

    if (detailsTab) {
      const index = parseInt(detailsTab.dataset.index || '');
      setActiveIndex(index);
    }
  };

  const scrollToElement = (el: HTMLElement, options = { offset: 250 }) => {
    // Generalized scrollTo that accounts for our header's height.
    const top = el.getBoundingClientRect().y || options.offset;
    const yPos = window.scrollY; // accounts for the header width
    window.scrollTo(0, top + yPos - options.offset);
  };
  const resetLocationHash = () => {
    // Reset hash so we can click the same link multiple times.
    // Remove fragment as much as it can go without adding an entry in browser history:
    window.location.replace('#');
    // slice off the remaining '#'
    if (typeof window.history.replaceState == 'function') {
      window.history.replaceState({}, '', window.location.href.slice(0, -1));
    }
    // TODO: commenting out the resetting of openChild here.
    // resetting this was collapsing the accordion as soon as it was opened.
    // setOpenChild(``);
  };
  return (
    <SectionWrapper data-testid="detailsSection" removePadding={true}>
      <Container>
        {isMobile ? (
          <Accordion.Wrapper
            openChild={openChild}
            scrollToOpenedContent
            allowMultipleOpened
          >
            {menuArray.map(({ menuTitle, slug }, index: number) => (
              <Accordion.Item key={menuTitle} id={`details-${index}-${slug}`}>
                <S.AccordionHeading onClick={() => handleClick(menuTitle)}>
                  {menuTitle}
                </S.AccordionHeading>
                <Accordion.Child>{contentArray[index]}</Accordion.Child>
                <S.Divider />
              </Accordion.Item>
            ))}
          </Accordion.Wrapper>
        ) : (
          <DesktopMenu
            menuArray={menuArray}
            contentArray={contentArray}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
          />
        )}
      </Container>
    </SectionWrapper>
  );
};

export default DetailsSection;
