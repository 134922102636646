import React from 'react';
import ewAnalytics from 'utils/analytics';
import { ANALYTICS } from 'utils/constants/analytics';
import { CTA } from 'utils/types';

import * as S from './DescriptiveList.styles';
import SubHeadline from './SubHeadline';

export type DescriptiveListProps = {
  /** Heading at the top of the list */
  headline: string;
  /** Sanitized HTML text */
  subheadline?: string;
  /** Section anchor link call to action */
  cta?: CTA;
  /** List items: title, description, image */
  listItems: ListItem[] | [];
  /** Headline style control */
  hasLargerCentralizedHeadline: boolean;
  /** Function called when cta is clicked */
  onCtaClick?: (linkId: string) => void;
};

export type ListItem = {
  /** List item identifier */
  title: string;
  /** Sub-items separated by end-of-line (\n) */
  description: string;
  /** List item icon URL */
  image?: string;
};

/**
 * List container with an unorderelist with an icon, title and sub-items.
 */
function DescriptiveList({
  headline,
  subheadline,
  cta,
  listItems,
  hasLargerCentralizedHeadline,
  onCtaClick,
}: DescriptiveListProps): JSX.Element {
  const handleCtaClick = () => {
    if (onCtaClick) {
      onCtaClick(`${cta?.url?.toLowerCase()}`);
    }

    ewAnalytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        sectionHeader: headline,
        contentfulSection: 'DescriptiveList',
        ctaText: cta?.text,
      },
    });
  };

  return (
    <>
      {hasLargerCentralizedHeadline ? (
        <S.LargerCentralizedHeadline>{headline}</S.LargerCentralizedHeadline>
      ) : (
        <S.LargerNotCentralizedHeadline>
          {headline}
        </S.LargerNotCentralizedHeadline>
      )}

      <SubHeadline text={subheadline} cta={cta} onCtaClick={handleCtaClick} />

      <S.ListContainer>
        {listItems.map(({ title, description, image }, index) => {
          const subItems = description.split('\n');

          return (
            <S.ListItemWrapper
              key={index}
              constrainWidth={hasLargerCentralizedHeadline}
            >
              <S.ListItemIcon aria-hidden="true" src={image} alt={title} />

              <div>
                {title && <S.ListItemTitle>{title}</S.ListItemTitle>}
                <S.UnorderedList>
                  {subItems.map((subItem, index) => (
                    <S.ListSubItem key={index}>{subItem}</S.ListSubItem>
                  ))}
                </S.UnorderedList>
              </div>
            </S.ListItemWrapper>
          );
        })}
      </S.ListContainer>
    </>
  );
}

export default DescriptiveList;
